import React from 'react'

const ArrowUp = ({ width, height, color }) => {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M18 15L12 9L6 15" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArrowUp;