import React from 'react';
import classes from './Container.module.css';

type ContainerProps = {
  id?: string;
  children?: string | React.ReactNode;
  type?: 'large' | 'regular' | 'small' | 'extraLarge' | 'extrasmall' | 'medium';
  className?: any;
  style?: object;
  variant?: 'primary' | 'secondary';
};

const Container: React.FC<ContainerProps> = ({
  id,
  children,
  type = 'small',
  className,
  style,
  variant = 'primary',
}) => {
  return (
      <div
        id={id}
        className={`
          ${classes[variant === 'secondary' ? `rb-container-root-secondary` :`rb-container-root`]}
          ${classes[`rb-container-type-${type}`]}
          ${className ? className : null}
        `}
        style={style}
      >
        {children}
      </div>
  );
};

export default Container;
