import React from 'react';
import classes from './Typography.module.scss';

export enum Variant {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  body1 = 'body1',
  body2 = 'body2',
  body3 = 'body3',
  smallText = 'smallText',
  label1 = 'label1',
  label2 = 'label2',
  label3 = 'label3',
  smallLabel = 'smallLabel',
  display2Xl = 'display2Xl',
  displayXl = 'displayXl',
  displayLg = 'displayLg',
  displayMd = 'displayMd',
  text2XL = 'text2XL',
  textXl = 'textXl',
  textLg = 'textLg',
  textMd = 'textMd',
  textSm = 'textSm',
  textXs = 'textXs',
}

export enum Weight {
  regular = 'regular',
  medium = 'medium',
  semibold = 'semibold',
  bold = 'bold',
}

export enum Align {
  left = 'left',
  right = 'right',
  center = 'center'
}

type TypographyProps = {
  id?: string;
  children: string | React.ReactNode;
  className?: any;
  variant?: Variant | string;
  weight?: Weight | string;
  align?: Align | string;
  color?: string;
  style?: object;
  onClick?: () => void,
  link?: boolean;
  paragraph?: boolean;
  span?: boolean;
};

const Typography: React.FC<TypographyProps> = ({
  id,
  children,
  paragraph = false,
  span = false,
  variant = 'body1',
  weight = 'regular',
  align = 'left',
  color,
  className,
  style,
  onClick,
  link = false
}) => {
  if (paragraph) 
    return <p
        id={id}
        onClick={onClick}
        className={`
            ${classes['rb-typography-root']}
            ${classes[`rb-typography-${variant}`]}
            ${classes[`rb-typography-weight-${weight}`]}
            ${classes[`rb-typography-align-${align}`]}
            ${className || ''}
        `}
        style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
      >
        {children}
      </p>;

  if (span) 
    return <span
      id={id}
      onClick={onClick}
      className={`
          ${classes['rb-typography-root']}
          ${classes[`rb-typography-${variant}`]}
          ${classes[`rb-typography-weight-${weight}`]}
          ${classes[`rb-typography-align-${align}`]}
          ${className || ''}
      `}
      style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
    >
      {children}
    </span>;

  switch (variant) {
    case Variant.h1:
      return (
        <h1
          id={id}
          onClick={onClick}
          className={`
            ${classes['rb-typography-root']}
            ${classes['rb-typography-h1']}
            ${classes[`rb-typography-weight-${weight}`]}
            ${classes[`rb-typography-align-${align}`]}
            ${className || ''}
          `}
          style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
        >
          {children}
        </h1>
      );
    case Variant.h2:
      return (
        <h2
          id={id}
          onClick={onClick}
          className={`
              ${classes['rb-typography-root']}
              ${classes['rb-typography-h2']}
              ${classes[`rb-typography-weight-${weight}`]}
              ${classes[`rb-typography-align-${align}`]}
              ${className || ''}
            `}
          style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
        >
          {children}
        </h2>
      );
    case Variant.h3:
      return (
        <h3
          id={id}
          onClick={onClick}
          className={`
            ${classes['rb-typography-root']}
            ${classes['rb-typography-h3']}
            ${classes[`rb-typography-weight-${weight}`]}
            ${classes[`rb-typography-align-${align}`]}
            ${className || ''}
          `}
          style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
        >
          {children}
        </h3>
      );
    case Variant.h4:
      return (
        <h4
          id={id}
          onClick={onClick}
          className={`
            ${classes['rb-typography-root']}
            ${classes['rb-typography-h4']}
            ${classes[`rb-typography-weight-${weight}`]}
            ${classes[`rb-typography-align-${align}`]}
            ${className || ''}
          `}
          style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
        >
          {children}
        </h4>
      );
    case Variant.h5:
      return (
        <h5
          id={id}
          onClick={onClick}
          className={`
            ${classes['rb-typography-root']}
            ${classes['rb-typography-h5']}
            ${classes[`rb-typography-weight-${weight}`]}
            ${classes[`rb-typography-align-${align}`]}
            ${className || ''}
          `}
          style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
        >
          {children}
        </h5>
      );
    case Variant.display2Xl:
      return (
        <h1
          id={id}
          onClick={onClick}
          className={`
            ${classes['rb-typography-root']}
            ${classes['rb-typography-display2Xl']}
            ${classes[`rb-typography-weight-${weight}`]}
            ${classes[`rb-typography-align-${align}`]}
            ${className || ''}
          `}
          style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
        >
          {children}
        </h1>
      );
    case Variant.displayXl:
      return (
        <h2
          id={id}
          onClick={onClick}
          className={`
              ${classes['rb-typography-root']}
              ${classes['rb-typography-displayXl']}
              ${classes[`rb-typography-weight-${weight}`]}
              ${classes[`rb-typography-align-${align}`]}
              ${className || ''}
            `}
          style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
        >
          {children}
        </h2>
      );
    case Variant.displayLg:
      return (
        <h3
          id={id}
          onClick={onClick}
          className={`
            ${classes['rb-typography-root']}
            ${classes['rb-typography-displayLg']}
            ${classes[`rb-typography-weight-${weight}`]}
            ${classes[`rb-typography-align-${align}`]}
            ${className || ''}
          `}
          style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
        >
          {children}
        </h3>
      );
    case Variant.displayMd:
      return (
        <h4
          id={id}
          onClick={onClick}
          className={`
            ${classes['rb-typography-root']}
            ${classes['rb-typography-displayMd']}
            ${classes[`rb-typography-weight-${weight}`]}
            ${classes[`rb-typography-align-${align}`]}
            ${className || ''}
          `}
          style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
        >
          {children}
        </h4>
      );
    case Variant.text2XL:
      return (
        <h5
          id={id}
          onClick={onClick}
          className={`
            ${classes['rb-typography-root']}
            ${classes['rb-typography-text2XL']}
            ${classes[`rb-typography-weight-${weight}`]}
            ${classes[`rb-typography-align-${align}`]}
            ${className || ''}
          `}
          style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
        >
          {children}
        </h5>
      );
    default:
      return (
        <div
          id={id}
          onClick={onClick}
          className={`
              ${classes['rb-typography-root']}
              ${classes[`rb-typography-${variant}`]}
              ${classes[`rb-typography-weight-${weight}`]}
              ${classes[`rb-typography-align-${align}`]}
              ${className || ''}
          `}
          style={{ color, cursor: link ? 'pointer' : 'inherit', ...style }}
        >
          {children}
        </div>
      );
  }
};

export default Typography;