import React from 'react';
import classes from './Chip.module.scss';
import CloseIcon from '../../icons/CloseIcon';
import Box from '../box/Box';

export enum Variant {
    cancelled = 'cancelled',
    inProgress = 'inProgress',
    completed = 'completed',
    open = 'open',
    normal = 'chip',
    footer = 'footer'
}

type ChipProps = {
    variant: Variant | String,
    onClick?: () => void,
    value?: String,
    selected?: boolean,
    showCloseIcon?: boolean,
    onClose?: any,
    id?: string,
    icon?: any,
    className?: string,
    text?: string,
    isNotClickable?: boolean
}

const Chip: React.FC<ChipProps> = ({ id, variant, onClick, value, selected, onClose, showCloseIcon, icon, className, isNotClickable=false, text }) => {
    return (
        <>

            {value ?
                <button id={id} className={ `${className && className} ${isNotClickable ? classes['rb-chip-notClickable'] :classes[`rb-chip-${variant}`]} ${(selected && !isNotClickable) ? classes['rb-selected'] : ''}`} onClick={onClick}>
                   <Box display='flex' alignItems='center' gap={icon && 8}>
                   {icon && <div className={classes['rb-icon']}>{icon}</div>}
                    {onClose || showCloseIcon ? <Box display='flex' alignItems='center' gap={8}><span>{value}</span> {showCloseIcon && <Box display='flex' alignItems='center' onClick={onClose || onClick}><CloseIcon height={16} width={16} /></Box>}</Box> : value}
                    </Box>
                </button>
            : <button id={id} className={` ${classes[`rb-chip-${variant}`]} ${selected ? classes['rb-selected'] : ''}`} >
                {text ? text :variant === 'inProgress' ? 'In progress' : variant === 'completed' ? 'Resolved' : variant === 'open'? 'Open' : ''}
            </button>}
        </>

    )
}

export default Chip;