import React from 'react';

const SendMoney = ({ color , width, height }) => {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_4250_45424)">
        <path
          d="M13.3249 10.2937L9.43577 14.1828M9.58203 14.4488L12.8628 21.0317C13.1206 21.5488 13.2495 21.8074 13.4153 21.8802C13.5593 21.9434 13.7247 21.9351 13.8617 21.8579C14.0195 21.769 14.1219 21.4988 14.3268 20.9586L20.8261 3.82421C21.0261 3.29687 21.1261 3.0332 21.0687 2.86332C21.0187 2.71573 20.9028 2.59985 20.7553 2.54992C20.5854 2.49245 20.3217 2.59246 19.7944 2.79249L2.65407 9.29402C2.11544 9.49833 1.84612 9.60049 1.75718 9.758C1.67994 9.89479 1.67148 10.0599 1.73433 10.2039C1.80671 10.3697 2.06417 10.4988 2.57911 10.7571L9.22471 14.0906C9.31316 14.1349 9.35738 14.1571 9.39568 14.1867C9.42967 14.213 9.46014 14.2435 9.48632 14.2776C9.51583 14.316 9.5379 14.3602 9.58203 14.4488Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4250_45424">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendMoney;
