import React from 'react'

const Language = ({ height = 21, width = 21, color = "#1160F7" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M12.25 2.25C14.7513 4.98835 16.1728 8.54203 16.25 12.25C16.1728 15.958 14.7513 19.5116 12.25 22.25M12.25 2.25C9.74872 4.98835 8.32725 8.54203 8.25 12.25C8.32725 15.958 9.74872 19.5116 12.25 22.25M12.25 2.25C6.72715 2.25 2.25 6.72715 2.25 12.25C2.25 17.7728 6.72715 22.25 12.25 22.25M12.25 2.25C17.7728 2.25 22.25 6.72715 22.25 12.25C22.25 17.7728 17.7728 22.25 12.25 22.25M2.75002 9.25H21.75M2.75 15.25H21.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Language